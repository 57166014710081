import React from "react";

import slugify from "slugify";
import { graphql } from "gatsby";
import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import ProductCategoryTwo from "../components/features/product-category/product-category-two";
export default function collections({ data }) {
  const categories = data.allCategories.nodes.filter((c) => c._id !== "1");
  return (
    <MainLayout>
     
     <Seo title={`${process.env.GATSBY_STORE_NAME}- Collections`} />
      <div className="main">
     

        <h1 className="d-none">{process.env.GATSBY_STORE_NAME} - Collections</h1>
        <PageHeader title="Categories" />
        <Breadcrumb parent1={["collections"]} />

        <div className="page-content">
          <div className="container">
            <hr className="mb-4" />

            <div className="row justify-content-center">
              {categories.map(({ name,seoMetaData, itemCount }, i) => (
                <div key={i} className="col-md-6 col-lg-4">
                  <ProductCategoryTwo
                    category={name}
                    itemCount={itemCount}
                    link={`/collections/${
                      seoMetaData?.slug || slugify(name.toLowerCase())
                    }`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/collections.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    allCategories {
      nodes {
        _id
        name
        seoMetaData {
          slug
        }
        itemCount
        thumbnail {
          id
          childImageSharp {
            gatsbyImageData(
              width: 370
              height: 200
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
