import React from "react";
import { Link } from "gatsby";

function ProductCategoryTwo(props) {
  const {  category, link, itemCount, adClass = "" } = props;

  return (
    <div className="banner banner-cat">
     
        <div
          style={{
            display: "flex",
            height: 160,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f2f2f2",
          }}
        ></div>
      

      <div className="banner-content banner-content-overlay text-center">
        <h2 className={`banner-title ${adClass}`}>{category}</h2>
        <h4 className="banner-subtitle-dark">{itemCount} Products</h4>
        <Link to={link} className="banner-link">
          Shop Now
        </Link>
      </div>
    </div>
  );
}

export default React.memo(ProductCategoryTwo);
